.body_3493 #slider {
  position: relative;
  height: calc(100vh - 262px);
  max-height: 730px;
  min-height: 350px;
}

#slider {
  width: 100%;
  position: relative;
  height: calc(50vh - 262px);
  min-height: 350px;
  overflow: hidden;

  &:before {
    @include before(100%, 450px);
    @include bg(url(/i/design/gradient.png), cover);
    background-repeat: repeat-x;
    z-index: -1;
  }
}

.body_3493 .backstretch {
  &:before {
    @include before(100%, 100%);
    background: #000;
    opacity: 0;
  }

}

.backstretch {
  position: relative;

  img {
    top: 0px !important;
  }
}