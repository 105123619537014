// -----------------------------------//
// Menu colours
// -----------------------------------//


// avoid sub menu going outside of viewport (2nd to + last menu item target)
ul.nav li:nth-last-of-type(2) ul {
	right: 0;
	left: inherit;
	padding-right: 0;
}

ul.nav li:nth-last-of-type(2) ul li ul {
	right: 100%;
	left: inherit;
	padding-right: 0;
}

ul.nav li:last-of-type ul {
	right: 0;
	left: inherit;
	padding-right: 0;
}

ul.nav li:last-of-type ul li ul {
	right: 100%;
	left: inherit;
	padding-right: 0;
}


// Widden on larger screens' INSIDE PAGES (IE friendly)
@media only screen and (min-width: 1121px) {
	.body_3493 nav#navholder {
		.wrapper {
			width: 990px;
			max-width: 990px;
		}
	}

	nav#navholder {
		.wrapper {
			width: 90%;
			max-width: 1500px;
		}
	}

	.body_3493 ul.nav {
		width: 990px;
		max-width: 990px;
	}

	ul.nav {
		width: 100%;
		max-width: 1500px;
		margin: 0 auto;
	}

}

// end of 1921px

@media only screen and (max-width: 1120px) {
	nav#navholder {
		.wrapper {
			width: 990px;
			max-width: 990px;
		}
	}

	ul.nav {
		width: 990px;
		max-width: 990px;
	}

}

// end of 1120px


// -----------------------------------//
// NAVIGATION WRAPPERS
// -----------------------------------//

nav#navholder {
	background: $menu-background-bg;

	z-index: 999;
	height: auto;
	padding: 0;
	@extend .clearfix;
	clear: both;

}

// -----------------------------------//
// MAIN NAVIGATION
// -----------------------------------//

ul.nav {

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 auto;
	list-style: none;
	padding: 0;

	// Top Level List

	li {
		flex-grow: 1;
		margin: 0;
		padding: 0;
		background: none;
		vertical-align: middle;
		display: inline-block;
		position: relative;
		text-align: center;
		white-space: nowrap;

		a {
			width: 100%;
			min-height: 50px;
			line-height: 1.3;
			display: block;
			text-align: center;
			font-size: 18px;
			color: $menu-text-colour;
			font-weight: 500;
			padding: 14px 8px 0;
			word-wrap: break-word;
			min-width: 40px;
			white-space: break-spaces;
			margin: auto;
		}
	}

	>li:hover>a,
	>li>a:hover {
		background: $sup-menu-bg;
		text-decoration: none;
	}

	// 2nd Level List

	li {
		ul {
			position: absolute;
			left: -999em;
			margin: 0;
			padding: 0 20px 20px;
			background: none;
			opacity: 0;
			visibility: hidden;
			transition: (all 0.3s ease-in-out);
		}

		&:hover ul {
			left: auto;
			margin-left: -20px;
			opacity: 1;
			visibility: visible;
			transition: (all 0.3s ease-in-out);
			z-index: 9999;
		}

		&:active ul {
			transition: (opacity 0.1s ease-in-out);
		}
	}

	// 2nd Level List Items

	li li {
		float: none;
		display: block;
		padding: 0;
		height: auto;
		line-height: 35px;
		margin: 0;

		a {
			background: $sub-menu-bg;
			padding: 10px 12px 10px;
			min-width: 180px;
			min-height: auto;
			float: none;
			top: auto;
			display: block;
			position: static;
			text-align: left;
			text-decoration: none;
			font-size: 14px;
			text-transform: none;
			font-weight: 300;
		}

		&:hover,
		&:hover>a,
		a:hover,
		a:focus {
			background: $sup-menu-bg;
			color: #fff;
		}
	}

	>li>ul>li:first-of-type,
	>li>ul>li:last-of-type {
		background: none;
	}

	>li>ul>li:first-of-type a,
	>li>ul>li:first-of-type a:hover,
	>li>ul>li:first-of-type a:focus {
		border-radius: 0px 0px 0 0;
	}

	>li>ul>li:last-of-type a,
	>li>ul>li:last-of-type a:hover,
	>li>ul>li:last-of-type a:focus {
		border-radius: 0 0 10px 10px;
	}

	>li>ul>li>ul>li a {
		border-radius: 0 !important;
	}

	// Prepend 'more' arrow to parent list items

	li li a.parent:after {
		margin: -1px 0 0 10px;
		content: url('/i/design/lite/nav-right-arrow-white.png');
		opacity: 1;
		line-height: 1;
	}

	// Black arrow uncomment to use
	// li li a.parent:after { margin: -1px 0 0 10px; content: url('/i/design/lite/nav-right-arrow-black.png'); opacity: 1; line-height: 1; }

	// 3rd Level List

	ul ul {
		z-index: 505;
	}

	li:hover ul ul {
		left: 100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
		transition: (all 0.3s ease-in-out);
	}

	ul li:hover ul {
		left: 100%;
		top: 0;
		opacity: 1;
		visibility: visible;
		transition: (all 0.3s ease-in-out);
	}

}