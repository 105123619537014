// @media only screen and (max-width: 1340px) {
// 	#ofsted-btn  { top: 270px;  }
// 	.partnership-trust { top: 240px; }
// }


@media only screen and (max-width: 1200px) {
	#news-diary-sec .wrapper {
		width: calc(100% - 160px);
	}
}

// end of 1180px

@media only screen and (max-width: 1080px) {
	.wrapper {
		width: 95%;
	}

	nav#navholder .wrapper {
		width: 95%;
	}

	ul.nav {
		max-width: 100%;
	}

	// SUP NAV 1080
	#sup-nav {
		.wrapper {
			#sup-nav-box {
				p {
					&:nth-of-type(2) {
						padding: 9px 5px;
						;
					}
				}
			}
		}
	}

	#tools-dock {
		padding: 9px 10px;
	}
}

// end of 1080px

@media only screen and (max-width: 990px) {
	.body_3493 #slider {
		height: calc(100vh - 296px);
	}

	.body_3493 .backstretch {
		&:before {
			opacity: 0.2;
		}
	}

	#sup-nav {
		margin: 0 auto 44px;
		position: relative;
		top: 44px;
		z-index: 10;

		.wrapper {
			#sup-nav-box {
				justify-content: center;

				p,
				a[href^="mailto:"] {
					display: none;
				}
			}
		}
	}

	// Header
	header {
		padding: 16px 0 40px;
		height: auto;

		.wrapper {
			padding: 0;
		}
	}

	#logo-index {
		margin: 0 auto 20px;
		float: none;
		display: inline-block;

		a {
			img#logo {}
		}
	}

	#ofsted-btn {
		top: 390px;
	}

	.partnership-trust {
		top: 360px;
	}

	//Tools dock
	#tools-dock {}

	// -----------------------------------//
	// GENERAL STYLES
	// -----------------------------------//

	#social-sec {
		width: calc(40% - 15px);
	}

	#SideHeadingDiary,
	#SideHeadingNews {
		width: calc(60% - 15px);


	}

	// Body
	body {
		min-width: 250px;
		overflow-x: hidden;
	}

	h1 {
		font-size: 2em;
	}

	// Wrappers
	.wrapper {
		width: 90%;
	}

	// Mainholder
	#mainholder {
		width: 100%;
		padding: 0;
	}

	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column {
		width: 90%;
		padding: 30px;
		margin: 5% auto;
		float: none;
		display: block;
	}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight {
		width: 90%;
		padding: 30px;
		margin: 5% auto;
		float: none;
		display: block;
	}

	//Home page alert
	.modal-home {
		.message-modal {
			width: 90%;
			margin: 0 5%;
			left: 0;
			top: 20%;
			padding: 0;

			.modal-content {
				.modal-text {
					padding: 3%;
				}

				a.close-modal {
					position: absolute;
					top: 3%;
					right: 3%;
					background: #fff;
					padding: 3px;
					border-radius: 0px 0px 0px 7px;
				}
			}
		}
	}

	// Global
	img {
		max-width: 100%;
		height: auto;
	}

	table {
		max-width: 100%;
	}

	//Responsive tables
	table.tableborder {
		width: 100%;
		overflow: scroll;
		white-space: nowrap;
		table-layout: fixed;
		position: relative;

		&:before {
			content: "";
			width: 30px;
			height: 100%;
			position: absolute;
			top: 0;
			right: -1px;
			background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#33000000', GradientType=1);
		}

		tbody {
			width: 100%;
			float: left;
			white-space: normal;
			overflow-x: scroll;
		}
	}

	// Calendar
	#calendar {
		.fc-header td {
			display: block;
			width: 100%;
		}

		.fc-button-content {
			font-family: Arial;
		}

		.fc-header-right {
			text-align: left;
		}

		.fc-header-center {
			text-align: left;
		}
	}

	// Forms
	form div input[type="text"],
	form div textarea,
	form span textarea,
	form div input[type="textarea"],
	form div input[type="password"] {
		width: 200px;
	}

	form div span.button {
		padding-left: 0;
	}

	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	footer {
		padding: 30px 0;
		height: auto;

		.wrapper {
			text-align: center;

			h2,
			p,
			a {
				text-align: center;
				margin: 10px auto;
				padding: 0;
				display: block;
				float: none;

				&:before {
					display: none;
				}
			}

			a {
				word-break: break-all;
				display: inline-block;
				margin: 0 auto;
			}
		}
	}

	#contact {
		float: none;
	}

	#register {
		float: none;
		margin: 20px auto 0;
	}

	.sub-footer {
		p {
			text-align: center;
			margin: 5px auto;
			float: none;
		}

		#credit {
			float: none;
		}
	}

	#awards {
		display: none;
	}

}

// end of 990px

@media only screen and (max-width: 1100px) {
	#news-diary-sec .wrapper {
		width: 90%;
	}

	#facebook {
		float: none;
		margin: 0 auto 50px;
		width: 100%;

		h2.sec-title {
			margin: 0 auto 20px;
			transform: none;
			position: relative;
			text-align: center;
			top: auto;
			left: auto;
			right: auto;
		}

		h2 {
			margin: 0 0 20px 0;
		}
	}

	#SideHeadingNews {
		float: none;
		margin: 0 auto 50px;
		width: 100%;

		h2.sec-title {
			margin: 0 auto 20px;
			transform: none;
			position: relative;
			text-align: center;
			top: auto;
			right: auto;
			left: auto;
		}
	}
}

// end of 800px

// -----------------------------------//
// MOBILE
// -----------------------------------//

@media only screen and (max-width: 650px) {
	#ofsted-btn {
		// top: 270px;
	}

	.partnership-trust {
		top: 390px;
	}
}

@media only screen and (max-width: 600px) {
	header {
		padding: 16px 0 80px;
	}

	#logo-index {
		a {
			float: none;
			display: inline-block;
			margin: 0 auto;
			font-size: 40px;

			span {
				font-size: 26px;
			}

			img#logo {
				float: none;
				display: block;
				margin: 0 auto 10px;
				width: 80px;
			}
		}
	}


	// #ofsted-btn  { top: 330px; left: 20px;  }
	// .partnership-trust { top: 300px; }

}

// end of 600px

@media only screen and (max-width: 400px) {

	#SideHeadingDiary,
	#SideHeadingNews {
		ul.SideList {
			flex-direction: column;
			align-items: center;

			li {
				margin: 10px 0;
				width: 100%;
				max-width: 225px;

				&:nth-child(3) {
					margin-bottom: 10px;
				}
			}
		}

		a.sec-button {
			float: none;
			max-width: none;

			&:nth-of-type(2) {
				float: none;
			}
		}
	}

}

// end of 400px

@media only screen and (max-width: 350px) {
	header {
		padding: 16px 0;
	}

	#logo-index {
		a {
			font-size: 30px;

			span {
				font-size: 20px;
			}
		}
	}

	#ofsted-btn,
	.partnership-trust {
		display: none;
	}

}

// end of 350px

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

	#element {
		property: value;
	}

}

// end of internet explorer