// -----------------------------------//
// Variables
// -----------------------------------//

// gold #92834a
// gold dark #7c6f3d
// green #1c593d
// green dark #144b32

// -----------------------------------//
// Sup Nav colours
// -----------------------------------//
$sup-menu-bg: #580201;
$sup-menu-highlight: lighten($sup-menu-bg, 5%);
$sup-menu-colour: #fff;

// -----------------------------------//
// Nav Menu colours
// -----------------------------------//
$menu-background-bg: #7C0602;
$menu-text-colour: #fff;
$sub-menu-bg: #7C0602;

// Hovers
$menu-hover: #E4032F;
$sub-menu-hover: #E4032F;

//MEANMENU
$mm-reveal-background: #7C0602;
$mm-navigation-background: #7C0602;

// -----------------------------------//
// HEADER LOGO SECTION
// -----------------------------------//
$header-background: #fff;
$logo-colour: #E4032F;

// -----------------------------------//
// NEWS DIARY SECTION
// -----------------------------------//

$scroll-hvr: #7C0602; //twitter scrollbar hover
$date-bg: #e8c501; // diary date bg
$date-bg-hover: darken($date-bg, 5%);
$date-box-bg: #fff; // diary entry bg

// buttons
$btn-bg: #851a16;
$btn-hvr: lighten($btn-bg, 5%);

// -----------------------------------//
// FOOTER colours
// -----------------------------------//
$footer-bg: #fff;
$footer-colour: #7C0602;
$footer-link: #7C0602;
$footer-link-hvr: #580201;
$footer-register: #1c593d;

//subfooter
$subfooter-bg: #580201;
$subfooter-link: #fff;
$subfooter-link-hvr: #fff;


// -----------------------------------//
// INSIDE PAGES
// -----------------------------------//
$link-color: deepskyblue;
$h1-color: #7C0602;
$h2-color: #580201;
$h3-bg-color: #7C0602;
$h4-bg-color: #580201;

//Related pages
$related-pages-h2: #fff;
$related-pages-bg: #7C0602;
$related-pages-hvr: #313131;


//News
$little-date-line: #E4032F;

//Subscribe
$subscribe-bg-colour: #E4032F;

//Diary list inside page colours
$diary-date-bg: #313131;
$add-to-calendar-bg: #E4032F;

//Gallery
$gallery-bg: #313131;

//Photo box text colours (DARK background)
// Date
$box-light-date: #dbed2b;

//Photo box text colours (LIGHT background)
// Date
$box-dark-date: #313131;

//Grid and List view styles
h2.ListingHead {
	background: #313131;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//
$search-highlight-background-color: #313131;
$search-highlight-color: #fff;

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//
$table-highlight-color: #fff;
$table-highlight-background-color: #313131;
$table-border-color: #313131;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//
$blockquote-background-color: #fff;
$blockquote-color: #313131;
$blockquote-speech-mark-color: #E4032F;
$blockquote-border-color: #E4032F;